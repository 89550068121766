/* eslint-disable @typescript-eslint/no-empty-function */
import { Logger } from "./Logger";

export class NoopLogger extends Logger {
    silly(): void {}
    debug(): void {}
    info(): void {}
    warn(): void {}
    error(): void {}

}